:root {
	--primary-color: #f4511e;
	--primary-light-color: #ff844c;
	--primary-dark-color: #b91400;
	--on-primary-color: #fafafa;
	--secondary-color: #b2ff59;
	--secondary-light-color: #e7ff8c;
	--secondary-dark-color: #7ecb20;
	--on-secondary-color: #212121;
    --tertiary-color: #2E294E;
	--bg-color: #eaf0f8;
	--soft-text: #8c9095;
}

.inimmed-progress {
	background-color: var(--secondary-color);
}

* {
    box-sizing: border-box;
	overflow: hidden;
    transition: 0.3s;
}

#root {
	height: 100%;
}

a {
    text-decoration: none;
}

a:link {
	color: var(--primary-color);
}

a:visited {
	color: var(--secondary-dark-color);
}

p {
	word-wrap: break-word;
}

.body-bg {
	display: flex;
    position: relative;
	flex-direction: column;
	margin: 0;
	width: 100%;
	height: 100%;
	background-color: var(--bg-color);
    /* background: linear-gradient(170deg, rgba(199,237,211,1) 0%, rgba(73,177,199,1) 100%); */
}

@font-face {
	font-family: 'Ubuntu';
	src: url(./Fonts/Ubuntu/Ubuntu-Regular.ttf);
}

@font-face {
	font-family: 'Ubuntu-Medium';
	src: url(./Fonts/Ubuntu/Ubuntu-Medium.ttf);
}

h1 {
    margin: 0;
}

.option-button-strip {
    display: flex;
    justify-content: right;
    width: 100%;
    gap: 15px;
    flex-wrap: wrap;
    overflow: visible;
}

.button-primary {
	border: 0;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	transition: 0.3s;
	color: var(--on-primary-color);
	background-color: var(--primary-color);
	font-size: 1.25em;
	width: fit-content;
	height: fit-content;
	padding: 10px 20px;
    cursor: pointer;
}

.button-primary:hover {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background-color: var(--on-primary-color);
    color: var(--primary-color);
}

.button-primary-inactive {
	border: 0;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	transition: 0.3s;
	color: var(--on-primary-color);
	background-color: gray;
	font-size: 1.25em;
	width: fit-content;
	height: fit-content;
	padding: 12px 24px;
}

.button-secondary {
    border: none;
	outline: solid 1px var(--primary-color);
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	transition: 0.3s;
	color: var(--primary-color);
    background-color: var(--on-primary-color);
	font-size: 1.25em;
	width: fit-content;
	height: fit-content;
	padding: 12px 24px;
    cursor: pointer;
}

.button-secondary:hover {
    background-color: var(--on-primary-color);
    color: var(--secondary-dark-color);
	outline: solid 1px transparent;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.button-danger {
	border: 0;
    outline: solid 1px transparent;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	transition: 0.3s;
	color: var(--on-primary-color);
	background-color: red;
    font-size: 1.25em;
	width: fit-content;
	height: fit-content;
	padding: 10px 20px;
    cursor: pointer;
}

.button-danger:hover {
	background-color: var(--on-primary-color);
    outline: solid 1px red;
	color: red;
}

.back-button {
    border: none;
    background-color: transparent;
    box-shadow: none;
    width: 30px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
}

.back-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.sidenav-button {
	letter-spacing: 0;
	font-size: 1.25em;
	display: flex;
	align-items: center;
	box-sizing: content-box;
	padding: 0 1em 0 0;
	background-color: transparent;
	box-shadow: 0 5px 10px transparent;
	color: var(--soft-text);
	font-family: 'Ubuntu-Medium';
	border-radius: 0.3em;
	border: 0;
	font-size: 1.25em;
	transition: 0.3s;
	height: 2em;
    outline: solid 1px transparent;
    cursor: pointer;
}

.sidenav-button:hover {
    outline: solid 1px rgba(0, 0, 0, 0.1);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-button {
	font-size: 1em;
    padding: 0.5em;
	white-space: nowrap;
	background: none;
    border: none;
	background-color: var(--primary-color);
    color: var(--on-primary-color);
	/* color: var(--primary-color); */
	letter-spacing: normal;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: 0.3s;
}

.dropdown-button:hover {
	color: var(--primary-color);
	background-color: var(--on-primary-color);
}

table {
	border-collapse: collapse;
	/* border-radius: 1em 1em 0 0; */
	table-layout: fixed;
	min-width: 100%;
}

th {
	color: var(--on-primary-color);
	padding: 0.75em 0 0.75em 0;
	background-color: var(--primary-color);
	cursor: default;
	user-select: none;
}

td {
	text-align: center;
	padding: 1em 0.5em 1em 0.5em;
	white-space: nowrap;
	text-overflow: ellipsis;
}

tr:hover {
	background-color: var(--primary-light-color);
	color: var(--on-primary-color);
	cursor: pointer;
}

tr:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.1);
}

tr:nth-child(even):hover {
	background-color: var(--primary-light-color);
	color: var(--on-primary-color);
}

select {
    padding: 0.5em;
    width: 100%;
    font-size: 1em;
    border: none;
    outline: solid 1px var(--secondary-dark-color);
    border-radius: 10px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
}

select:focus {
    outline: solid 1px var(--primary-color);
}

select:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}

textarea {
    padding: 0.5em;
    width: 100%;
    max-width: 100%;
    font-size: 1em;
    border: none;
    outline: solid 1px var(--secondary-dark-color);
    border-radius: 10px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
}

textarea:focus {
	outline: solid 1px var(--primary-color);
}

textarea:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}

.top-separator {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 1em 0 1em 0;
}

.form-container {
	box-sizing: border-box;
	padding: 20px;
	display: flex;
	flex-direction: column;
    align-items: center;
    animation: floatFromDown ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    overflow: visible;
}

.form-container h2 {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    width: 100%;
    text-align: left;
    margin: 0;
}

.form-input-div {
	display: flex;
    gap: 1em;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
    margin-bottom: 10px;
    overflow: visible;
}

.form-input-div .form-input {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
    overflow: visible;
}

.form-input-plus {
    display: flex;
    align-items: center;
    overflow: visible;
    position: relative;
}

label {
    margin-bottom: 8px;
}

.form-text-input {
    background-color: white;
    padding: 0.5em;
    width: 100%;
    font-size: 1em;
    border: none;
    outline: solid 1px var(--secondary-dark-color);
    border-radius: 10px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.form-text-input:focus {
    outline: solid 1px var(--primary-color);
}

.form-text-input:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}

.text-input {
    border: none;
    border-bottom: 2px solid var(--secondary-color);
    outline: none;
    box-sizing: border-box;
    padding: 0.5em;
    font-size: 1.25em;
    background-color: rgba(0, 0, 0, 0.03);
	transition: 0.3s;
    width: 100%;
}

.text-input:focus {
    border-bottom: 2px solid var(--primary-color);
}

.date-time-input {
    padding: 0.5em;
    width: 100%;
    font-size: 1.25em;
    border: none;
    outline: solid 1px var(--secondary-dark-color);
    border-radius: 10px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
}

.date-time-input:focus {
    outline: solid 1px var(--primary-color);
}

.date-time-input:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}

.menu-card {
	height: fit-content;
	border-radius: 1.5em;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	cursor: pointer;
	transition: 0.3s;
}

.item-selector-item {
    margin: 0;
    padding: 5px;
    font-size: 1em;
    overflow: visible;
    cursor: pointer;
    border-radius: 5px;
}

.item-selector-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.no-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.menu-card:hover {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.calendar-event-p-in-progress {
    margin: 0;
    margin-bottom: 10px;
    padding: 5px;
    border-left: solid 5px var(--secondary-color);
    outline: solid 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: fit-content;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    transition: 0.3s;
}

.calendar-event-p-in-progress:hover {
    border-left: solid 5px var(--primary-light-color);
    outline: solid 1px var(--primary-light-color);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}

.calendar-event-p-pending {
    margin: 0;
    margin-bottom: 10px;
    padding: 5px;
    border-left: solid 5px rgb(255, 222, 74);
    outline: solid 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: fit-content;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    transition: 0.3s;
}

.calendar-event-p-pending:hover {
    border-left: solid 5px var(--primary-light-color);
    outline: solid 1px var(--primary-light-color);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}

.calendar-event-p-urgent {
    margin: 0;
    margin-bottom: 10px;
    padding: 5px;
    border-left: solid 5px #ee3636;
    width: 100%;
    height: fit-content;
    background-color: #ee3636;
    color: var(--on-primary-color);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    transition: 0.3s;
}

.calendar-event-p-urgent:hover {
    background-color: var(--on-primary-color);
    color: #ee3636;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}

.calendar-event-p-finished {
    margin: 0;
    margin-bottom: 10px;
    padding: 5px;
    border-left: solid 5px var(--secondary-dark-color);
    width: 100%;
    height: fit-content;
    background-color: var(--secondary-dark-color);
    color: var(--on-primary-color);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    transition: 0.3s;
}

.calendar-event-p-finished:hover {
    background-color: var(--on-primary-color);
    color: var(--secondary-dark-color);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}

.calendar-event-week-p {
    margin: 0;
    padding: 3px;
    border-radius: 5px;
    outline: solid 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--on-primary-color);
    width: 90%;
    cursor: pointer;
    transition: 0.3s;
}

.calendar-event-week-p:hover {
    outline: solid 1px var(--primary-light-color);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}

.calendar-day-div {
    height: 8em;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.calendar-day-div::-webkit-scrollbar {
    display: none;
}

.add-button {
    background: none;
    box-shadow: none;
    border: none;
    position: absolute;
    right: 0;
}

.add-button-icon {
    display: inline;
    transition: 0.3s;
}

.add-button-icon:hover {
    filter: invert(62%) sepia(13%) saturate(132%) hue-rotate(174deg) brightness(90%) contrast(87%);
}

.image-frame {
    width: 256px;
    height: 256px;
    outline: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.image-frame:hover {
    outline: solid 1px var(--primary-color);
}

.collapsable-header {
    padding: 5px;
    border-radius: 5px;
    margin: 0;
    cursor: pointer;
}

.collapsable-header:hover {
    background-color: rgba(0, 0, 0 , 0.1);
}

.own_bank_account_card {
    outline: solid 2px transparent;
    padding: 15px;
    box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.1);
    overflow: visible;
    user-select: none;
    cursor: pointer;
}

.own_bank_account_card:hover {
    outline: solid 2px var(--secondary-dark-color);
    transform: scale(1.05, 1.05);
}
 
@keyframes spin {
	0% { transform: rotate(0deg) }
	100% { transform: rotate(360deg) }
}

.float-from-down {
    animation: floatFromDown ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.float-from-right {
	animation: floatFromRight ease 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.float-from-up {
    animation: floatFromUp ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.detail-button-strip {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 4px;
}

.data-container {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0rem;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    gap: 1px;
    background-color: var(--primary-color);
    border-radius: 16px 16px 0px 0px;
}

.dataEntry {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.dataEntryTitleContainer {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.dataEntryTitle {
    margin: 0;
    background-color: var(--primary-color);
    color: var(--on-primary-color);
}

.dataEntryData {
    margin: 0;
    padding: 1rem;
    background-color: var(--on-primary-color);
}

@media only screen and (max-width: 768px) {
	.login-container {
		margin: auto;
		display: flex;
		flex-direction: column;
		max-width: 90%;
		background-color: white;
		border-radius: 20px;
		gap: 1em;
		box-shadow: 0 0.5rem 15px 5px rgba(0, 0, 0, 0.2);
        outline: solid 1px rgba(0, 0, 0, 0.1);
	}

    .button-strip {
        display: flex;
        justify-content: right;
        width: 100%;
        gap: 1em;
        flex-wrap: wrap;
        overflow: visible;
    }

    h1 {
        font-size: 1em;
    }

    .dataEntry {
        font-size: small;
    }

    .dataEntryTitleContainer {
        padding: 8px;
    }
    
    .dataEntryData {
        padding: 8px;
    }
}

@media only screen and (min-width: 768px) {
	.login-container {
		margin: auto;
		display: flex;
		flex-direction: column;
		max-width: 30%;
		background-color: white;
		border-radius: 20px;
		gap: 1em;
		box-shadow: 0 0.5rem 15px 5px rgba(0, 0, 0, 0.2);
        outline: solid 1px rgba(0, 0, 0, 0.1);
	}

    .button-strip {
        display: flex;
        justify-content: right;
        width: 100%;
        gap: 1em;
        flex-wrap: wrap;
        overflow: visible;
    }
}

.clickable-detail {
	transition: 0.3s;
    font-style: italic;
	cursor: pointer;
}

.clickable-detail:hover {
	color: var(--on-primary-color);
    background-color: var(--primary-light-color);
}

@keyframes floatFromRight {
	0% { transform: translateX(20%); opacity: 0;}
	100% { transform: translateX(0); opacity: 1; }
}

@keyframes floatFromDown {
    0% { transform: translateY(20%); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

@keyframes floatFromUp {
    0% { transform: translateY(-20%); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}